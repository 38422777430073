import * as React from "react"
import '../css/bootstrap.min.css';
import '../css/domforte.css';
import {Carousel, CarouselItem, Container, Nav, Navbar, ThemeProvider} from "react-bootstrap";
import {StaticImage} from "gatsby-plugin-image";
import IconDefinitions from "../components/icons/IconDefinitions";
import {Helmet} from "react-helmet";
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import DomforteHelmet from "./DomforteHelmet";


const IndexPage = ({data}) => {
    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <DomforteHelmet title={"DOMFORTE, вул. Петражицького - Жити в місті серед зелені"}/>
            <IconDefinitions/>

            <Navbar bg={'light'} variant={'light'} fixed={'top'} expand={'lg'}>

                <Container>
                    <Navbar.Brand href={'#home'}>
                        <StaticImage src={"../images/logo.webp"} placeholder={"none"} width={200} alt={"logo"}
                                     className={'align-text-bottom'}/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="domforte-navbar"/>
                    <Navbar.Collapse id="domforte-navbar">
                        <Nav className={'ms-auto nav-masthead'}>
                            <Nav.Link href={'#lokalizacja'}>Розташування</Nav.Link>
                            <Nav.Link href={'#oinwestycji'}>Про інвестицію</Nav.Link>
                            <Nav.Link href={'#domy'}>Будинки</Nav.Link>
                            <Nav.Link href={'#dostepnosc'}>Доступність</Nav.Link>
                            <Nav.Link href={'#kontakt'}>Контакти</Nav.Link>
                            <Nav.Link href={'tel:606388442'}>
                                <svg className="" width="1em" height="1em">
                                    <use href="#phone"/>
                                </svg>
                                &nbsp;606 388 442
                            </Nav.Link>
                            <Nav.Link href={'/'} className={'mx-1 align-bottom'}>
                                <img src={'/staticimg/pl.svg'} alt={"pL"} width={'20px'} height={'10px'}/>
                            </Nav.Link>
                            <Nav.Link href={'/en'} className={'mx-1'}>
                                <img src={'/staticimg/en.svg'} alt={"en"} width={'20px'} height={'10px'}/>
                            </Nav.Link>
                            <Nav.Link href={'/ua'} className={'mx-1'}>
                                <img src={'/staticimg/ua.svg'} alt={"ua"} width={'20px'} height={'10px'}/>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>

                </Container>
            </Navbar>


            <div style={{display: "grid", height: "80vh"}}>
                <StaticImage style={{gridArea: "1/1", zIndex: '-1'}}
                             src={"../images/widok5b_wide.jpg"}
                             placeholder="blurred" alt={"hero"}/>
                <div style={{gridArea: "1/1", position: "relative"}}
                     className="container-fluid d-flex flex-column align-items-center text-center">
                    <div className={'my-auto'}>
                        <h1 className={'display-3 text-white fw-bold bg-info bg-opacity-50'}>Краків, вул.
                            Петражицького</h1>
                        <h2 className={'display-5 text-white fw-bold bg-opacity-50 df-bg-green'}>
                            Жити в місті серед зелені
                        </h2>
                    </div>
                </div>
            </div>


            <div className="container px-4 py-5 retract-top" id="hanging-icons">
                <div className="row">
                    <div className="col-lg-4 my-3">
                        <div className={'mx-4 p-2 bg-light'}>
                            <div className="icon-square-retracted-top">
                                <svg className="bi" width="2em" height="2em">
                                    <use href="#thumbsup"/>
                                </svg>
                            </div>
                            <div className={'p-3'}>
                                <h4>Зручність і комфорт</h4>
                                <p className={'text-justify py-4'}> Зручність, функціональність і комфорт – це наші
                                    пріоритети. Кожен з наших будинків має на двох рівнях три кімнати, вітальню з кухнею
                                    і дві ванні кімнати. На першому поверсі є великий відкритий простір, де проходить
                                    спільне сімейне життя. Великі розсувні двері тераси зручно поєднують простір
                                    вітальні з садом.<br/><br/>
                                </p>
                                <a href="#domy" className="btn btn-primary">
                                    Дивитися плани приміщень
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 my-3">
                        <div className={'mx-4 p-2 bg-light'}>
                            <div className="icon-square-retracted-top">
                                <svg className="bi" width="2em" height="2em">
                                    <use href="#quality"/>
                                </svg>
                            </div>
                            <div className={'p-3'}>
                                <h4>Найвища якість</h4>
                                <p className={'text-justify py-4'}>Будинки відрізняються не тільки зручністю і
                                    комфортом, але також якістю використовуваних матеріалів і енергозбереженням. Ми
                                    використовуємо високоякісні матеріали. Вікна мають теплі рами та 3-камерні
                                    склопакети високої ізоляції. Високий стандарт опалення робить будинки теплими і
                                    такими, що відповідають самим останнім нормам.
                                </p>
                                <a href="#kontakt" className="btn btn-primary">
                                    Дізнатися більше
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 my-3">
                        <div className={'mx-4 p-2 bg-light'}>
                            <div className="icon-square-retracted-top">
                                <svg className="bi" width="2em" height="2em">
                                    <use href="#village"/>
                                </svg>
                            </div>
                            <div className={'p-3'}>
                                <h4>Дружня обстановка</h4>
                                <p className={'text-justify py-4'}>Будинки оточені зеленою зоною і новими приватними
                                    будинками. У безпосередній близькості знаходяться зупинка громадського транспорту,
                                    поштомат, продуктовий магазин, 4 дитячих садки, школа та станція швидкісної міської
                                    залізниці. Зручний доступ до центру Кракова, кільцевої дороги, Закопянки та Скавіни.<br/><br/>
                                </p>
                                <a href="#lokalizacja" className="btn btn-primary">
                                    Читати про розташування
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className={'container px-4 py-4 my-4'}>
                <div className={'pb-3'}><h1 className={'display-4 fw-bold sectionTitle'}>Продаж для іноземців</h1></div>
                <div className={"px-4 py-4 text-justify"}>
                    Купівля приміщень не вимагає отримання дозволу МВС. Це стосується всіх іноземців. Дозволи не мусять
                    отримувати жителі Європейського Союзу та Європейської Економічної Зони. Дозволи не мусять отримувати
                    також громадяни інших країн, наприклад, громадяни України і Білорусії. Приміщення продаються як
                    окремі житлові приміщення.
                </div>
            </div>
            <a name={'lokalizacja'}/>
            <div className={'container px-4 py-4 my-5'}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <div className={'pb-3'}><h1 className={'display-4 fw-bold sectionTitle'}>Насолоджуйтесь свіжим
                            повітрям</h1></div>
                        <div className={"px-4 py-4 text-justify"}>Інвестиція розташована в Кракові на вулиці
                            Петражицького 83 в частині Кракова, відомій як Сідзіна - це частина Кракова, що входить до
                            Району VIII Дембники, колишнє село під Краковом, приєднане до міста Кракова в 1973 році.
                            Сідзіна - найпівденніша частина Району VIII, розташована за межами кільцевої автодороги.
                            Найближче сусідство мікрорайону являє собою житлову забудову приватними будинками та
                            незабудовані території. Ви знайдете тут унікальні прогулянкові зони, школу, дитячий садок,
                            магазини, а також відмінну транспортну розв'язку до центру Кракова, завдяки швидкісній
                            міській залізниці, громадському транспорту та хорошим дорожнім сполученням з Ручаєм,
                            краківською кільцевою дорогою, Закопянкою та Скавіною.
                            <br/><br/>
                            <a href={'https://goo.gl/maps/syCSeh2v9r6iHrwx7'}>Подивитися на картах Google (Краків, вул.
                                Петражицького, 83)</a>
                        </div>
                    </div>
                    <div className={'col-md-6'}>
                        <div className={'card shadow-lg'}>
                            <StaticImage src={"../images/mapa1.png"} placeholder="" alt={"mapa1"}
                                         className={'align-text-bottom'}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'container px-4 py-4'}>
                <div className={'row'}>
                    <div className={'col-md-2 bg-light mx-3 my-5'}>
                        <div className="icon-square-retracted-top">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#train"/>
                            </svg>
                        </div>
                        <div className={'py-4 px'}>
                            Зупинка Польської Державної Залізниці та швидкісної міської залізниці - на відстані <b>800
                            м.</b>
                        </div>
                    </div>
                    <div className={'col-md-2 bg-light mx-3 my-5'}>
                        <div className="icon-square-retracted-top">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#bus"/>
                            </svg>
                        </div>
                        <div className={'py-4'}>
                            Автобусна зупинка (маршрути 175, 243, 475) <b>150m</b>
                        </div>
                    </div>
                    <div className={'col-md-2 bg-light mx-3 my-5'}>
                        <div className="icon-square-retracted-top">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#school"/>
                            </svg>
                        </div>
                        <div className={'py-4'}>
                            Початкова школа № 133 в Кракові <b>1100m</b>
                        </div>
                    </div>
                    <div className={'col-md-2 bg-light mx-3 my-5'}>
                        <div className="icon-square-retracted-top">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#kindergarten"/>
                            </svg>
                        </div>
                        <div className={'py-4'}>
                            Чотири дитячі садки <b>850m - 1100m</b>
                        </div>
                    </div>
                    <div className={'col-md-2 bg-light mx-3 my-5'}>
                        <div className="icon-square-retracted-top">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#supermarket"/>
                            </svg>
                        </div>
                        <div className={'py-4'}>
                            Продуктовий магазин (супермаркет Левіатан) і пекарня <b>500m</b>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'container px-4 py-4 my-5'}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <div className={'pb-3'}><h1 className={'display-5 fw-bold sectionTitle'}>Все поряд</h1></div>
                        <div className={"px-4 py-4 text-justify"}>
                            У цьому районі Ви знайдете початкову школу - 800 м, продуктовий супермаркет - 500 м, пекарню
                            - 450 м, чотири дитячих садки на відстані від 850 м до 1100 м, спортивний клуб "Сідзіна",
                            будинок культури і церкву.
                        </div>
                        <div className={'pb-3'}><h1 className={'display-5 fw-bold sectionTitle'}>Швидко дістатися до
                            центру</h1>
                        </div>
                        <div className={"px-4 py-4 text-justify"}>
                            На відстані 150 м знаходиться зупинка громадського транспорту для автобусних маршрутів 175,
                            243 і 475. Завдяки станції Польської Державної Залізниці та швидкісної міської залізниці Ви
                            за 20 хвилин дістанетесь до Центрального вокзалу та околиць Краківської Площі Ринок. Дуже
                            хороше дорожнє сполучення - виїзд на кільцеву дорогу - вузол Скавіна - 4 хвилини, вузол
                            Закопянська - 6 хвилин. До Ручаю Ви доберетеся за 7 хвилин завдяки Сідзінскому проїзду над
                            кільцевою дорогою - вулицею Дзяловського.
                        </div>

                    </div>
                    <div className={'col-md-6'}>
                        <div className={'card shadow-lg'}>
                            <StaticImage src={"../images/mapa2.png"} placeholder="" alt={"mapa2"}
                                         className={'align-text-bottom'}/>
                        </div>
                    </div>
                </div>
            </div>


            <a name={'oinwestycji'}/>
            <div className={'container-fluid px-0 py-4 my-5'} style={{display: "grid"}}>
                <StaticImage style={{gridArea: "1/1"}} layout="fullWidth" src={"../images/salon_wide.jpeg"}
                             placeholder="blurred" alt={""}/>
                <div style={{gridArea: "1/1", position: "relative"}}
                     className="container-fluid d-flex flex-column align-items-center">
                    <div className={'row my-auto w-75 h-100'}>
                        <div className={'col-lg-6 bg-white my-auto p-5'}>
                            <div><h1 className={'display-5 fw-bold sectionTitle text-left ;h'}>Живіть комфортно</h1>
                            </div>
                            <div className={'pt-4'}>
                                <p className={'text-justify'}>DOMFORTE Петражицького - це затишний мікрорайон, що
                                    складається з трьох двоквартирних приватних будинків. Кожна дворівнева секція має
                                    від 78 м2 до 85 м2. У будинках є просторі вітальні, з'єднані з кухнею, на верхньому
                                    поверсі є три кімнати, в кожному будинку є дві ванні кімнати.
                                </p>
                                <br/>
                                <p className={'text-justify'}>
                                    Секції мають свої незалежні сади, тобто прекрасні місця, щоб провести час з сім'єю
                                    на відкритому повітрі, грати з дітьми або організувати барбекю для друзів і сім'ї.
                                    Кожен з будинків має своє незалежне паркувальне місце.
                                </p><br/>
                                <p className={'text-justify'}>
                                    Сучасна архітектура при одночасному збереженні елегантної форми будинків і дуже
                                    великої функціональності приміщень - це козирі нашої інвестиції.
                                </p>
                                <br/>
                            </div>
                        </div>
                        <div className={'col-lg-6'}>
                        </div>

                    </div>
                </div>
            </div>

            <div className={'container px-4 py-4 my-5'}>
                <div className={'row'}>
                    <div className={'col-md-7 text-end'}>
                        <div className={"mx-auto"}>
                            <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                <a href={'/staticimg/salon.jpg'}>
                                    <img src={'/staticimg/salon_small.jpeg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/kuchnia.jpg'}>
                                    <img src={'/staticimg/kuchnia_small.jpeg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/lazienka.jpg'}>
                                    <img src={'/staticimg/lazienka_small.jpeg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/widok2b.jpg'}>
                                    <img src={'/staticimg/widok2b_small.jpg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/widok3b.jpg'}>
                                    <img src={'/staticimg/widok3b_small.jpg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/widok4b.jpg'}>
                                    <img src={'/staticimg/widok4b_small.jpg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/widok5b.jpg'}>
                                    <img src={'/staticimg/widok5b_small.jpg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/widok6b.jpg'}>
                                    <img src={'/staticimg/widok6b_small.jpg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/widok7b.jpg'}>
                                    <img src={'/staticimg/widok7b_small.jpg'} className={'mx-1 my-1'}/>
                                </a>
                                <a href={'/staticimg/widok1a_wide.jpeg'}>
                                    <img src={'/staticimg/widok1a_small.jpeg'} className={'mx-1 my-1'}/>
                                </a>
                            </LightGallery>
                        </div>
                    </div>
                    <div className={'col-md-4 my-5 my-md-0'}>
                        <p className={'text-justify'}>
                            Будинки відрізняються простою, але елегантною і сучасною архітектурою. Велика площа вікон
                            забезпечує освітлення і приємний настрій в кожній кімнаті. Гігантське розсувне вікно у
                            вітальні дозволяє плавно перетворити вітальню з садом в приємний, зелений і безпечний
                            житловий простір для Вас і Вашої родини. Використання матеріалів найвищої якості в поєднанні
                            з високою якістю виготовлення будинків і монтажу їх елементів забезпечує багаторічне
                            безпроблемне користування.
                        </p>
                        <br/>
                        <p className={'text-justify'}>
                            Будинки дуже добре утеплені, опалюються двоконтурним газовим котлом з сучасними
                            характеристиками, що дозволяє знизити витрати на опалення. Кімнати нагорі мають повну висоту
                            - в них немає скосів. Мікрорайон DOMFORTE Петражицького буде відмінним вибором для пар і
                            сімей з дітьми, даючи їм високий комфорт і зручність в оточенні зелені.</p>
                    </div>
                </div>
            </div>
            <div className={'container px-4 py-4'}>
                <div className={'row'}>
                    <div className={'col-md-3 px-5'}>
                        <div className="icon-square">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#slonce"/>
                            </svg>
                        </div>
                        <h4 className={'pt-3'}>Багато сонця у Вашому домі</h4>
                        <div className={'py-4'}>
                            <p className={'text-justify padding-5'}>Завдяки великій площі вікон, всі кімнати дуже добре
                                освітлені природним сонячним світлом, що забезпечує приємну атмосферу і комфортні умови
                                для роботи, навчання і розваг для всієї родини.</p>
                        </div>
                    </div>
                    <div className={'col-md-3  px-5'}>
                        <div className="icon-square">
                            <svg className="bi" width="3em" height="3em">
                                <use href="#przesuwneokno"/>
                            </svg>
                        </div>
                        <h4 className={'pt-3'}>Велике розсувне вікно у вітальні</h4>
                        <div className={'py-4'}>
                            <p className={'text-justify padding-5'}>У вітальні є величезне вікно шириною 320 см, яке
                                відкривається розсуванням, утворюючи вихід шириною 160 см, який не має порога і дозволяє
                                дуже зручно поєднати життєвий простір вітальні з садом</p>
                        </div>
                    </div>
                    <div className={'col-md-3  px-5'}>
                        <div className="icon-square">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#pelnawysokosc"/>
                            </svg>
                        </div>
                        <h4 className={'pt-3'}>Повна висота без скосів</h4>
                        <div className={'py-4'}>
                            <p className={'text-justify padding-5'}>У наших будинках поверх має повну висоту, що робить
                                весь простір кімнат придатним для ефективного використання. Скоси часто ускладнюють
                                комфортне облаштування простору, а також, через необхідність оздоблення гіпсокартонними
                                плитами, часто стають місцем тріщин і пошкоджень.</p>
                        </div>
                    </div>
                    <div className={'col-md-3  px-5'}>
                        <div className="icon-square">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#ogrzewanie"/>
                            </svg>
                        </div>
                        <h4 className={'pt-3'}>Економне опалення</h4>
                        <div className={'py-4'}>
                            <p className={'text-justify padding-5'}>Будинки дуже добре утеплені пінопластом з низьким
                                коефіцієнтом теплопровідності (0.031) та обігріваються сучасними двоконтурними газовими
                                котлами дуже гарної якості, завдяки чому Ви отримаєте точний контроль над
                                експлуатаційними витратами та опаленням.</p>

                        </div>
                    </div>
                </div>
            </div>
            <div className={'container px-4 py-4 my-5 bg-white'}>
                <Carousel interval={1500}>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/kuchnia_wide.jpeg" alt={"1"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/lazienka_wide.jpeg" alt={"2"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/salon_wide.jpeg" alt={"3"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok2b.jpg" alt={"4"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok3b.jpg" alt={"5"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok4b.jpg" alt={"6"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok5b.jpg" alt={"7"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok6b.jpg" alt={"8"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok7b.jpg" alt={"9"}/>
                    </CarouselItem>
                </Carousel>
            </div>

            <a name={'domy'}/>
            <div className={'container px-4 py-4 my-5'}>
                <div><h1 className={'display-4 fw-bold sectionTitle'}>Познайомтеся з будинком своєї мрії</h1></div>

                <div className={'pt-5'}>
                    <h3>Будинок варіант 1 - 84,65 м2</h3>
                    (84,65 м2 корисної площі + 1,9 м2 допоміжної корисної площі (h@lt;1.9 м))

                </div>
                <div className={'row my-5'}>
                    <div className={'col-md-8'}>
                        <StaticImage src={"../images/plan_parter.jpg"} placeholder="" alt={"plan_parter"}
                                     className={'align-text-bottom'}/>

                    </div>
                    <div className={'col-md-4'}>
                        <h4>Перший поверх</h4>41,96m2 корисної площі +<br/> 1,90 m2 допоміжної корисної площі
                        (h&lt;1.9m)
                        <div style={{"overflowX": "scroll"}}>
                            <table className={'w-100 niceTable mt-5'}>
                                <thead className={'text-capitalize'}>
                                <tr>
                                    <th>№</th>
                                    <th>ПРИМІЩЕННЯ</th>
                                    <th>ПЛОЩА[m2]</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={'text-start'}>1</td>
                                    <td className={'text-start'}>ТАМБУР</td>
                                    <td className={'text-start'}>4,13</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>2</td>
                                    <td className={'text-start'}>ВАННА КІМНАТА</td>
                                    <td className={'text-start'}>3,21</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>3</td>
                                    <td className={'text-start'}>ВІТАЛЬНЯ З КУХОННОЮ ЗОНОЮ</td>
                                    <td className={'text-start'}>34,62</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={'row my-5'}>
                    <div className={'col-md-8'}>
                        <StaticImage src={"../images/plan_pietro.jpg"} placeholder="" alt={"plan_pietro"}
                                     className={'align-text-bottom'}/>

                    </div>
                    <div className={'col-md-4'}>
                        <h4>Поверх</h4>42,69m2 корисної площі
                        <div style={{"overflowX": "scroll"}}>
                            <table className={'w-100 niceTable mt-5'}>
                                <thead className={'text-capitalize'}>
                                <tr>
                                    <th>№</th>
                                    <th>ПРИМІЩЕННЯ</th>
                                    <th>ПЛОЩА[m2]</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={'text-start'}>1</td>
                                    <td className={'text-start'}>ХОЛ</td>
                                    <td className={'text-start'}>3,34</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>2</td>
                                    <td className={'text-start'}>СХОДИ</td>
                                    <td className={'text-start'}>2,94</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>3</td>
                                    <td className={'text-start'}>ВАННА КІМНАТА</td>
                                    <td className={'text-start'}>5,84</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>4</td>
                                    <td className={'text-start'}>СПАЛЬНЯ</td>
                                    <td className={'text-start'}>10,15</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>5</td>
                                    <td className={'text-start'}>СПАЛЬНЯ</td>
                                    <td className={'text-start'}>11,53</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>6</td>
                                    <td className={'text-start'}>СПАЛЬНЯ</td>
                                    <td className={'text-start'}>8,89</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <h4 className={'my-5'}>Горище</h4>
                        <div>
                            <p>Кожен з будинків оснащений неопалюваним горищем з площею підлоги близько 40 м2, яке може
                                служити складським приміщенням. Вихід на горище - сходи, що спускаються зі стелі -
                                знаходиться в холі.</p>
                            <p>Ми також пропонуємо версію з утепленим горищем разом з оббивкою скосів і підлоги плитами
                                OSB (оплачується додатково)</p>
                        </div>
                    </div>
                </div>

            </div>

            <a name={'dostepnosc'}/>
            <div className={' container px-4 py-4 my-5'}>
                <div><h1 className={'display-4 fw-bold sectionTitle'}>Доступність</h1></div>
                <div>
                    Запланована дата завершення – до кінця червня 2023 року, а запланована дата здачі – до кінця вересня 2023 року
                </div>
                <StaticImage src={"../images/pzt3d.jpeg"} placeholder="" alt={"pzt"}
                             className={'align-text-bottom my-5'}/>
                <div className={'container px-4 w-100 my-5 py-5 py-4 bg-danger bg-opacity-25'}>
                    <h3>
                        УВАГА!<br/>
                    </h3>
                    <b>Усі приміщення на цьому етапі були продані. Починаючи з січня 2024 року, ми розпочнемо продаж двох додаткових приміщень в цій локації, з завершенням будівництва запланованим на четвертий квартал 2024 року. Якщо ви зацікавлені, будь ласка, зв'яжіться з нами за адресою biuro@domforte.pl</b>
                </div>
                <div className={'container px-4 w-100 my-5 py-2'}>
                    <div className="auto-resizable-iframe w-75">
                        <div>
                            <iframe src="https://www.youtube.com/embed/wKkZur2qjcU?si=KSjJVcx8bAcuYBoM" title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </div>
                </div> 
                <div className={'container px-4 w-100 my-4 py-4 '} style={{"overflow-x": "scroll"}}>
                    <table className={'w-100 text-center niceTable'}>
                        <thead>
                        <tr>
                            <th>ПОЗНАЧЕННЯ</th>
                            <th>ПОВЕРХНЯ</th>
                            <th>САД</th>
                            <th>ДОСТУПНІСТЬ</th>
                            <th>ПЛАН</th>
                            <th>ЦІНА</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>A</td>
                            <td>77,27m2</td>
                            <td>153m2</td>
                            <td>ПРОДАНИЙ</td>
                            {/*<td className={'df-text-green'}>ДОСТУПНИЙ</td>*/}
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_a_0.jpg">ПЕРШИЙ ПОВЕРХ</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_a_1.jpg">ПОВЕРХ</a>
                                </LightGallery></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>B</td>
                            <td>77,27m2</td>
                            <td>204m2</td>
                            <td>ПРОДАНИЙ</td>
                            {/*<td className={'df-text-green'}>ДОСТУПНИЙ</td>*/}
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_b_0.jpg">ПЕРШИЙ ПОВЕРХ</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_b_1.jpg">ПОВЕРХ</a>
                                </LightGallery></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td>C</td>
                            <td>84,65m2</td>
                            <td>198m2</td>
                            {/*<td>БРОНЮВАННЯ</td>*/}
                            {/*<td className={'df-text-green'}>ДОСТУПНИЙ</td>*/}
                            <td>ПРОДАНИЙ</td>
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_c_0.jpg">ПЕРШИЙ ПОВЕРХ</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_c_1.jpg">ПОВЕРХ</a>
                                </LightGallery></td>
                            <td>799 000 зл</td>

                        </tr>
                        <tr>
                            <td>D</td>
                            <td>84,65m2</td>
                            <td>86m2</td>
                            <td>ПРОДАНИЙ</td>
                            {/*<td>БРОНЮВАННЯ</td>*/}
                            {/*<td className={'df-text-green'}>ДОСТУПНИЙ</td>*/}
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_d_0.jpg">ПЕРШИЙ ПОВЕРХ</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_d_1.jpg">ПОВЕРХ</a>
                                </LightGallery></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>E</td>
                            <td>84,65m2</td>

                            <td>82m2</td>
                            <td>ПРОДАНИЙ</td>
                            {/*<td className={'df-text-green'}>ДОСТУПНИЙ</td>*/}
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_e_0.jpg">ПЕРШИЙ ПОВЕРХ</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_e_1.jpg">ПОВЕРХ</a>
                                </LightGallery></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td>F</td>
                            <td>84,65m2</td>
                            <td>126m2</td>
                            <td>ПРОДАНИЙ</td>

                            {/*<td className={'df-text-green'}>ДОСТУПНИЙ</td>*/}
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_f_0.jpg">ПЕРШИЙ ПОВЕРХ</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_f_1.jpg">ПОВЕРХ</a>
                                </LightGallery></td>
                            <td></td>

                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <a name={'dziennik'}/>
            <div className={'container px-4'}>
                <div><h1 className={'display-4 fw-bold sectionTitle'}>Журнал будівництва</h1></div>
                <div className={'row my-5 text-center'}>
                    <div className={'col-md-3'}></div>
                    <div className={'col-md-6'}>
                        <LightGallery plugins={[lgZoom]} mode="lg-fade">
                            <a href="/staticimg/dziennik/13.jpg">
                                <img
                                    className="mx-1 my-1"
                                    src="/staticimg/dziennik/13_thumb.jpg"
                                    alt={'2023-04-13'}/>
                            </a>
                            <a href="/staticimg/dziennik/12.jpg">
                                <img
                                    className="mx-1 my-1"
                                    src="/staticimg/dziennik/12_thumb.jpg"
                                    alt={'2022-06-26'}/>
                            </a>
                            <a href="/staticimg/dziennik/11.jpg">
                                <img
                                    className="img-responsive mx-1 my-1"
                                    src="/staticimg/dziennik/11_thumb.jpg"
                                    alt={'2022-06-26'}/>
                            </a>
                            <a href="/staticimg/dziennik/9.jpg">
                                <img className="img-responsive mx-1 my-1"
                                     src="/staticimg/dziennik/9_thumb.jpg"
                                     alt={'2022-05-20'}/>
                            </a>
                            <a href="/staticimg/dziennik/7.jpg">
                                <img className="img-responsive mx-1 my-1"
                                     src="/staticimg/dziennik/7_thumb.jpg"
                                     alt={'2022-04-02'}/>
                            </a>
                            <a href="/staticimg/dziennik/6.jpg">
                                <img className="img-responsive mx-1 my-1"
                                     src="/staticimg/dziennik/6_thumb.jpg"
                                     alt={'2022-03-21'}/>
                            </a>
                            <a href="/staticimg/dziennik/2.jpg">
                                <img className="img-responsive mx-1 my-1"
                                     src="/staticimg/dziennik/2_thumb.jpg"
                                     alt={'2022-03-01'}/>
                            </a>
                        </LightGallery>
                    </div>
                    <div className={'col-md-3'}></div>
                </div>
            </div>
            <a name={'kontakt'}/>
            <div className={'container px-4'}>
                <div><h1 className={'display-4 fw-bold sectionTitle'}>Контакти</h1></div>
                <div className={'pt-3'}>
                    Будь ласка, зв'яжіться з нами електронною поштою <b>biuro@domforte.pl</b> і телефоном <b>+48
                    606-388-442</b> з понеділка по п'ятницю з 10:00 до 16:00.
                </div>
                <div className='py-4 fw-bold'>
                    Інвестиція в даний час знаходиться в процесі будівництва - ми рекомендуємо Вам записатися
                    на зустріч, щоб оглянути нерухомість.
                </div>
                <div>
                    Запланована дата завершення – до кінця червня 2023 року, а запланована дата здачі – до кінця вересня 2023 року
                </div>
                <div className={'row pt-3'}>
                    <div className={'col-md-4'}>
                        <b>ТОВ «DOMFORTE»</b><br/>
                        вул. Замкнєнта 10/1.5<br/>
                        30-554 Краків<br/>
                        тел. +48 606-388-442<br/>
                        email: biuro@domforte.pl<br/>
                    </div>


                </div>

            </div>

            <div className="container">
                <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                    <p>Рекламна листівка та візуалізація є наочного характеру. На етапі реалізації можуть відбутися
                        коригування. Сайт не є пропозицією в розумінні положень Цивільного кодексу.</p>
                    <p className="col-md-4 mb-0 text-muted">&copy; 2022 Domforte Sp. z o.o.</p>
                </footer>
            </div>
        </ThemeProvider>
    )
        ;
}

export default IndexPage
